import React from "react";
import Grid from "@material-ui/core/Grid";
// import LinearProgress from "@material-ui/core/LinearProgress";
import SiteWrapper from "./SiteWrapper";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/onboarding-styles";

const useStyles = makeStyles(styles);

export default function OnboardingLayout(props) {
  const { children, step, title, mobile } = props;
  const classes = useStyles();
  return (
    <div>
      <SiteWrapper>
        {/* <LinearProgress
          variant="determinate"
          value={step === 3 || step === 4 ? 100 : step * 30}
        /> */}
        {step === 4 ? (
          ""
        ) : (
          <p
            style={{ marginLeft: mobile ? "25px" : "25%" }}
          >{`step ${step} of 3 `}</p>
        )}
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <h1 className={classes.viewTitle}>{title}</h1>
            {children}
          </Grid>
        </Grid>
      </SiteWrapper>
    </div>
  );
}
