import React from "react";
//import SideNavigation from '../SideNavigation'
import { makeStyles } from "@material-ui/core/styles";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
// import Typography from "@material-ui/core/Typography";
// import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function SiteWrapper(props) {
  const { children } = props;
  // const [toggleNav, setToggleNav] = React.useState(false);

  const classes = useStyles();

  // const toggleSideNav = () => {
  //   setToggleNav(!toggleNav);
  // };

  return (
    <div>
      <div className={classes.root}>
        {/* <AppBar position="static">
          <Toolbar style={{ minHeight: "10vh" }}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleSideNav}
            >
              <MenuIcon style={{ width: "1.5em", height: "1.5em" }} />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Book With Baffin Cabins
            </Typography>
          </Toolbar>
        </AppBar> */}
      </div>
      {/*<SideNavigation open={toggleNav} navToggle={toggleSideNav} />*/}
      <div style={{ marginTop: "5px" }}>{children}</div>
    </div>
  );
}
