import React from "react";
import OnboardingLayout from "../components/layout/OnboardingLayout";
import TextField from "@material-ui/core/TextField";
import Continue from "../components/buttons/Continue";
import Cancel from "../components/buttons/Cancel";
import styles from "../assets/jss/onboarding-styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function ContactInfo(props) {
  const { handleChange, changeView, clientInfo, mobile } = props;
  const classes = useStyles();

  return (
    <OnboardingLayout
      step={1}
      title="Contact Information"
      changeView={changeView}
      mobile={mobile}
    >
      <div>
        <br />
        <TextField
          required
          className={mobile ? classes.mobileInputField : classes.inputField}
          id="name"
          label="Full Name"
          variant="outlined"
          value={clientInfo.name}
          onChange={(e) => handleChange(e)}
        />
        <br />
        <TextField
          required
          className={mobile ? classes.mobileInputField : classes.inputField}
          id="phone"
          label="Phone"
          value={clientInfo.phone}
          variant="outlined"
          onChange={(e) => handleChange(e)}
        />
        <br />
        <TextField
          className={mobile ? classes.mobileInputField : classes.inputField}
          id="email"
          label="Email"
          value={clientInfo.email}
          variant="outlined"
          onChange={(e) => handleChange(e)}
        />
        <br />
        <br />

        <TextField
          required
          className={mobile ? classes.mobileInputField : classes.inputField}
          id="tripType"
          select
          label="Trip Type"
          value={clientInfo.tripType}
          //  helperText="If you need a boat ride to and from the cabin select Yes"
          onChange={(e) => handleChange(e)}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >
          <option key="0" value="-">
            - select -
          </option>
          <option key="1" value="Cabin Rental">
            Cabin Rental
          </option>
          <option key="2" value="Guided Fishing Trip">
            Guided Fishing Trip
          </option>
          <option key="3" value="Cabin Rental & Guided Trip">
            Cabin Rental & Guided Trip
          </option>
          <option key="4" value="All Inclusive Package">
            All Inclusive Package
          </option>
        </TextField>

        <br />

        <div
          className={mobile ? classes.mobileButtonGroup : classes.buttonGroup}
        >
          <Cancel changeView={changeView} step={1} />
          <Continue
            nextView={2}
            onClick={() => changeView(2)}
            disabled={
              clientInfo.tripType.length < 2 ||
              clientInfo.name.length < 2 ||
              clientInfo.phone.length < 12
                ? true
                : false
            }
          />
        </div>
      </div>
    </OnboardingLayout>
  );
}
