import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import OnboardingCustomer from "./components/layout/OnboardingCustomer";

function App() {
  const {
    REACT_APP_ENV,
    REACT_APP_BCA_DEV_URL,
    REACT_APP_BCA_PRODUCTION_URL,
    REACT_APP_ZAPIER_URL,
  } = process.env;
  if (REACT_APP_ENV === "development") {
    window.bcaApiUrl = REACT_APP_BCA_DEV_URL;
    window.bcaZapierUrl = REACT_APP_ZAPIER_URL;
  }
  if (REACT_APP_ENV === "production") {
    window.bcaApiUrl = REACT_APP_BCA_PRODUCTION_URL;
    window.bcaZapierUrl = REACT_APP_ZAPIER_URL;
  }
  return (
    <Router>
      <div>
        <Route exact path="/" component={OnboardingCustomer} />
      </div>
    </Router>
  );
}

export default App;
