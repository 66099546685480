import React, { Fragment } from "react";
import OnboardingLayout from "../components/layout/OnboardingLayout";
import TextField from "@material-ui/core/TextField";
import Continue from "../components/buttons/Continue";
import Cancel from "../components/buttons/Cancel";
import styles from "../assets/jss/onboarding-styles";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function TripDetails(props) {
  const {
    handleChange,
    changeView,
    clientInfo,
    handleTripType,
    mobile,
  } = props;
  const classes = useStyles();

  React.useEffect(() => {
    handleTripType(clientInfo.tripType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInfo.tripType]);

  return (
    <OnboardingLayout
      step={2}
      title="Trip Details"
      changeView={changeView}
      mobile={mobile}
    >
      <div>
        <br />
        <TextField
          id="arivalDate"
          className={mobile ? classes.mobileInputField : classes.inputField}
          type="date"
          variant="outlined"
          value={moment(clientInfo.arivalDate).format("YYYY-MM-DD")}
          label={
            clientInfo.tripType === "Guided Fishing Trip"
              ? "Guided Trip Date mm/dd/yyyy"
              : "Arival Date mm/dd/yyyy"
          }
          onChange={(e) => handleChange(e)}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            required: true,
            minLength: 10,
            maxLength: 10,
            title: "Please Enter Valid Trip Date",
          }}
        />
        <br />
        <br />
        {clientInfo.tripType !== "Guided Fishing Trip" ? (
          <Fragment>
            <TextField
              className={mobile ? classes.mobileInputField : classes.inputField}
              id="tripDuration"
              select
              label="How many nights would you like to stay?"
              value={clientInfo.tripDuration}
              onChange={(e) => handleChange(e)}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              <option key="0" value="0">
                - select -
              </option>
              <option key="1" value={1}>
                1
              </option>
              <option key="2" value={2}>
                2
              </option>
              <option key="3" value={3}>
                3
              </option>
              <option key="4" value={4}>
                4
              </option>
              <option key="5" value={5}>
                5
              </option>
            </TextField>
            <br />
            <br />
            <TextField
              required
              className={mobile ? classes.mobileInputField : classes.inputField}
              id="transportation"
              select
              label="Transportation Needed"
              value={clientInfo.transportation}
              helperText="If you need a boat ride to and from the cabin select Yes"
              onChange={(e) => handleChange(e)}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              <option key="0" value="0">
                - select -
              </option>
              <option key="1" value="Yes">
                Yes
              </option>
              <option key="2" value="No">
                No
              </option>
            </TextField>
          </Fragment>
        ) : (
          <Fragment>
            <TextField
              className={mobile ? classes.mobileInputField : classes.inputField}
              id="fishingTripDuration"
              select
              label="Half day or full day?"
              value={clientInfo.fishingTripDuration}
              onChange={(e) => handleChange(e)}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              <option key="0" value="0">
                - select -
              </option>
              <option key="1" value="Half Day">
                Half Day
              </option>
              <option key="2" value="Full Day">
                Full Day
              </option>
            </TextField>
          </Fragment>
        )}

        <br />
        <br />
        <TextField
          className={mobile ? classes.mobileInputField : classes.inputField}
          id="partySize"
          select
          label="How many people in your group?"
          value={clientInfo.partySize}
          onChange={(e) => handleChange(e)}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >
          <option key="0" value="0">
            - select -
          </option>
          <option key="1" value={1}>
            1
          </option>
          <option key="2" value={2}>
            2
          </option>
          <option key="3" value={3}>
            3
          </option>
          <option key="4" value={4}>
            4
          </option>
          <option key="5" value={5}>
            5
          </option>
          <option key="6" value={6}>
            6
          </option>
        </TextField>
        <br />
        <br />
        <div
          className={mobile ? classes.mobileButtonGroup : classes.buttonGroup}
        >
          <Cancel changeView={changeView} text="Back" />
          <Continue
            nextView={3}
            onClick={(e) => changeView(3)}
            disabled={
              clientInfo.tripType === "Guided Fishing Trip"
                ? clientInfo.arivalDate === moment().format("MM/DD/YYYY") ||
                  clientInfo.partySize === "" ||
                  clientInfo.partySize === "0" ||
                  clientInfo.fishingTripDuration === "" ||
                  clientInfo.fishingTripDuration === "0"
                  ? true
                  : false
                : (
                    clientInfo.arivalDate === moment().format("MM/DD/YYYY") ||
                    clientInfo.partySize === "" ||
                    clientInfo.partySize === "0" ||
                    clientInfo.tripDuration === "" ||
                    clientInfo.tripDuration === "0" ||
                    clientInfo.transportation === "" ||
                    clientInfo.transportation === "0"
                      ? true
                      : false
                  )
                ? true
                : false
            }
          />
        </div>
      </div>
    </OnboardingLayout>
  );
}
