const inputField = {
  padding: "10px",
  width: "50%",
};

const mobileInputField = {
  padding: "10px",
  width: "97%",
  textAlign: "center",
};

const buttonGroup = {
  padding: "10px",
  width: "50%",
  margin: "0 auto",
};

const mobileButtonGroup = {
  padding: "10px",
  width: "97%",
  margin: "0 auto",
};

const viewTitle = {
  marginBottom: "0px",
};

const continueButton = {
  marginLeft: "10px",
  width: "63%",
  padding: "10px",
};

const mobileContinueButton = {
  marginLeft: "10px",
  width: "60%",
  padding: "10px",
};

const mobileContinueButtonFin = {
  marginLeft: "10px",
  width: "85%",
  padding: "10px",
};

const cancelButton = {
  width: "35%",
  padding: "10px",
};

const confirmText = {
  maxWidth: "50%",
  margin: "0 auto",
  fontSize: "20px",
  lineHeight: "150%",
};

const mobileConfirmText = {
  maxWidth: "92%",
  margin: "0 auto",
  fontSize: "20px",
  lineHeight: "145%",
};

const link = {
  textDecoration: "none",
};

export default {
  inputField,
  mobileInputField,
  viewTitle,
  buttonGroup,
  mobileButtonGroup,
  continueButton,
  mobileContinueButton,
  cancelButton,
  confirmText,
  mobileConfirmText,
  mobileContinueButtonFin,
  link,
};
