import React from "react";
import OnboardingLayout from "../components/layout/OnboardingLayout";
import Continue from "../components/buttons/Continue";
import styles from "../assets/jss/onboarding-styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function Finished(props) {
  const { changeView, clientInfo, mobile } = props;
  const classes = useStyles();

  return (
    <OnboardingLayout
      title={`Thanks, ${clientInfo.name}`}
      step={4}
      changeView={changeView}
      mobile={mobile}
    >
      <div>
        <br />
        <div
          className={mobile ? classes.mobileConfirmText : classes.confirmText}
        >
          <p>
            We look forward to providing you and your party an awesome
            experiance! We'll reach out as soon as possible with additional
            details and pricing.{" "}
          </p>
        </div>
        <br />
        <div style={{ width: "100%" }}>
          <a
            href="https://www.facebook.com/baffincabins"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <Continue view="last" text="Baffin Cabins Facebook" step={4} />
          </a>
        </div>
      </div>
    </OnboardingLayout>
  );
}
