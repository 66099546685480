import moment from "moment";

export const numberWithCommas = (numberToFormat) =>
  numberToFormat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const formatPhone = (phone) => {
  let val = phone.replace(/\D/g, "");
  let newVal = "";

  if (val.length > 4) {
    phone = val;
  }

  if (val.length > 3 && val.length < 7) {
    newVal += val.substr(0, 3) + "-";
    val = val.substr(3);
  }

  if (val.length > 5) {
    newVal += val.substr(0, 3) + "-";
    newVal += val.substr(3, 3) + "-";
    val = val.substr(6);
  }

  newVal += val;
  return newVal;
};

export const generateLastThirtyDays = (array, now, timePeriod) => {
  let recents = [];
  array.forEach((res) => {
    if (moment(res.arivalDate).isBetween(timePeriod, now)) {
      recents.push(res);
    }
  });
  return recents;
};

export const formatDates = (array) => {
  let formatted = [];
  array.forEach((record) => {
    let date = moment(record).toDate();
    let finalDate = date.setHours(0, 0, 0, 0);
    let gDate = moment(finalDate).toDate();
    let fDate = gDate.toString();
    formatted.push(fDate);
  });
  return formatted;
};

export const mobileRender = (width) => {
  if (width > 770) {
    return false;
  } else {
    return true;
  }
};

export const squashAllResDates = (array) => {
  let squashed = [];
  array.forEach((record) => {
    record.reservationDates.forEach((d) => {
      squashed.push(d);
    });
    return squashed;
  });
  return squashed;
};

// This is weak and should be refactored
export const isSameDay = (a, b) => {
  // eslint-disable-next-line eqeqeq
  if (a == b) {
    return true;
  } else {
    return false;
  }
};
// This is weak and should be refactored
export const getDateRange = (date1, date2) => {
  var d1 = moment(date1).format("MM/DD/YYYY").toString();
  const d2 = moment(date2).format("MM/DD/YYYY").toString();
  const submitDates = [];
  while (d1 <= d2) {
    let ndate = moment(d1).add(1, "days").format("MM/DD/YYYY");
    submitDates.push(d1);
    // eslint-disable-next-line no-redeclare
    var d1 = ndate;
  }
  return submitDates;
};

export const filterByMonth = (array, month) => {
  let filterMonth = moment(month).format("MMM");
  let newArray = [];
  array.filter((record) => {
    if (moment(record.arivalDate).format("MMM") === filterMonth) {
      record.month = filterMonth;
      newArray.push(record);
    }
    return newArray;
  });
  return newArray;
};
