import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import styles from "../../assets/jss/onboarding-styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function Cancel(props) {
  const { changeView, step, text } = props;
  const classes = useStyles();

  return (
    <Fragment>
      {step === 1 || step === 5 ? (
        <NavLink to={`/`} className={classes.link}>
          <Button variant="outlined" className={classes.cancelButton}>
            {text ? text : "Cancel"}
          </Button>
        </NavLink>
      ) : (
        <Button
          onClick={(e) => changeView(step - 1)}
          variant="outlined"
          className={classes.cancelButton}
        >
          {text ? text : "Cancel"}
        </Button>
      )}
    </Fragment>
  );
}
