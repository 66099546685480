import React from "react";
import OnboardingLayout from "../components/layout/OnboardingLayout";
import Continue from "../components/buttons/Continue";
import Cancel from "../components/buttons/Cancel";
import styles from "../assets/jss/onboarding-styles";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles(styles);

export default function Confirmation(props) {
  const { handleSubmit, handleChange, changeView, clientInfo, mobile } = props;
  const classes = useStyles();

  return (
    <OnboardingLayout
      step={3}
      title="Submit Reservation"
      changeView={changeView}
      mobile={mobile}
    >
      <div>
        <p>Everything look right?</p>
        <div
          className={mobile ? classes.mobileConfirmText : classes.confirmText}
        >
          {clientInfo.tripType !== "Guided Fishing Trip" ? (
            <p>{`You're requesting a ${clientInfo.tripType} ariving on ${moment(
              clientInfo.arivalDate
            ).format("dddd, MMMM Do")}. You'll stay ${
              clientInfo.tripDuration
            } nights and will be departing the cabin on ${moment(
              clientInfo.arivalDate
            )
              .add(clientInfo.tripDuration, "days")
              .format("dddd, MMMM Do")}.`}</p>
          ) : (
            <p>{`You're requesting a ${clientInfo.fishingTripDuration.toLowerCase()} guided fishing trip on ${moment(
              clientInfo.arivalDate
            ).format("dddd, MMMM Do")}.`}</p>
          )}
        </div>
        <br />
        <div
          className={mobile ? classes.mobileConfirmText : classes.confirmText}
        >
          <TextField
            multiline
            id="customerMessage"
            variant="outlined"
            label="Any additionl comments?"
            value={clientInfo.customerMessage}
            onChange={(e) => handleChange(e)}
            className={mobile ? classes.mobileInputField : classes.inputField}
            InputLabelProps={{ shrink: true }}
          ></TextField>
        </div>
        <br />
        <div
          className={mobile ? classes.mobileButtonGroup : classes.buttonGroup}
        >
          <Cancel changeView={changeView} step={3} text="Back" />
          <Continue text="Submit" onClick={() => handleSubmit(clientInfo)} />
        </div>
      </div>
    </OnboardingLayout>
  );
}
