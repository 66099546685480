import React from 'react';
// import { sortBy } from 'lodash';
import ContactInfo from '../../views/ContactInfo';
import TripDetails from '../../views/TripDetails';
import Confirmation from '../../views/Confirmation';
import Finished from '../../views/Finished';
import { formatPhone, getDateRange, mobileRender } from '../../utils';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
// import Backdrop from "@material-ui/core/Backdrop";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

const today = moment().format('MM/DD/YYYY').toString();
const onMobile = mobileRender(window.innerWidth);

export default function OnboardingCustomer() {
	const [obStep, setObStep] = React.useState(1);
	// const [loading, setLoading] = React.useState(false);
	const [mobile] = React.useState(onMobile);
	const [snackOpen, setSnackOpen] = React.useState(false);
	const [clientInfo, setClientInfo] = React.useState({
		createDate: today,
		arivalDate: today,
		tripDuration: '',
		onboardingStep: '4',
		inquiryId: '',
		partySize: '',
		customerMessage: '',
		email: '',
		phone: '',
		name: '',
		transportation: '',
		tripType: '',
		proposedDates: '',
		fishingTripDuration: '',
	});

	const handleTripType = (type) => {
		if (type === 'Guided Fishing Trip') {
			setClientInfo({
				...clientInfo,
				transportation: 'NA',
				tripDuration: '1',
			});
		} else {
			setClientInfo({
				...clientInfo,
				fishingTripDuration: 'NA',
			});
		}
	};

	const handleChange = (e) => {
		if (e.target.id === 'arivalDate') {
			setClientInfo({
				...clientInfo,
				arivalDate: moment(e.target.value).format('MM/DD/YYYY'),
			});
		} else if (e.target.id === 'phone') {
			setClientInfo({
				...clientInfo,
				phone: formatPhone(e.target.value),
			});
		} else {
			setClientInfo({
				...clientInfo,
				[e.target.id]:
					e.target.type === 'number'
						? parseInt(e.target.value)
						: e.target.value,
			});
		}
	};

	const changeView = (viewId) => {
		setObStep(viewId);
	};

	const handleSnackClose = () => {
		setSnackOpen(false);
	};

	// TODO: update AWS billing so we can use this functionality again.

	//   const getNewInquiryId = async () => {
	//     const apiCall = await fetch(`${window.bcaApiUrl}/inquiries/all`, {
	//       method: "GET",
	//     });
	//     const response = await apiCall.json();
	//     const sorted = sortBy(response, "id");
	//     setClientInfo({
	//       ...clientInfo,
	//       inquiryId: sorted[sorted.length - 1].id + 1,
	//     });
	//   };

	const getNewInquiryId = () => {
		const randomInt = Math.floor(Math.random() * 10000) + 10000;
		setClientInfo({
			...clientInfo,
			inquiryId: randomInt,
		});
	};

	const handleResponse = (res) => {
		// setLoading(false);
		if (res.status === 200) {
			setSnackOpen(true);
			changeView(4);
		} else {
			setSnackOpen(true);
		}
	};

	const gmailNotification = async () => {
		const dateRanges = await getDateRange(
			clientInfo.arivalDate,
			moment(clientInfo.arivalDate)
				.add(clientInfo.tripDuration, 'days')
				.format('MM/DD/YYYY')
		);
		await fetch(window.bcaZapierUrl, {
			method: 'POST',
			body: JSON.stringify({
				inquiry_id: clientInfo.inquiryId.toString(),
				create_date: clientInfo.createDate,
				arival_date: clientInfo.arivalDate,
				customer_message: clientInfo.customerMessage,
				name: clientInfo.name,
				email: clientInfo.email,
				phone: clientInfo.phone,
				trip_duration: clientInfo.tripDuration,
				transportation: clientInfo.transportation,
				proposed_dates: dateRanges,
				trip_type: clientInfo.tripType,
				party_size: clientInfo.partySize,
				onboarding_step: clientInfo.onboardingStep,
				inquiry_status: 'New Inquiry',
				fishing_trip_duration: clientInfo.fishingTripDuration,
				link: `https://chartersafari.com/single-inquiry?id=${clientInfo.inquiryId.toString()}&auth=baffincabinsemail`,
			}),
		});
		setSnackOpen(true);
		changeView(4);
	};

	const submitClient = async (clientInfo) => {
		// setLoading(true);

		// handle response is not doing anything here, should refactor in future

		gmailNotification().then((res) => {
			handleResponse(res);
		});

		// const dateRanges = await getDateRange(
		// 	clientInfo.arivalDate,
		// 	moment(clientInfo.arivalDate)
		// 		.add(clientInfo.tripDuration, 'days')
		// 		.format('MM/DD/YYYY')
		// )

		// TODO: update AWS billing so we can use this functionality again.

		// fetch(`${window.bcaApiUrl}/inquiries`, {
		// 	method: 'PUT',
		// 	body: JSON.stringify({
		// 		inquiry_id: clientInfo.inquiryId.toString(),
		// 		create_date: clientInfo.createDate,
		// 		arival_date: clientInfo.arivalDate,
		// 		customer_message: clientInfo.customerMessage,
		// 		name: clientInfo.name,
		// 		email: clientInfo.email,
		// 		phone: clientInfo.phone,
		// 		trip_duration: clientInfo.tripDuration,
		// 		transportation: clientInfo.transportation,
		// 		proposed_dates: dateRanges,
		// 		trip_type: clientInfo.tripType,
		// 		party_size: clientInfo.partySize,
		// 		onboarding_step: clientInfo.onboardingStep,
		// 		inquiry_status: 'New Inquiry',
		// 		fishing_trip_duration: clientInfo.fishingTripDuration,
		// 	}),
		// }).then((res) => {
		// 	handleResponse(res);
		// });
	};

	const handleSubmit = (clientInfo) => {
		submitClient(clientInfo).then((res) => {
			console.log(res);
		});
	};

	React.useEffect(() => {
		getNewInquiryId();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderViews = (viewId) => {
		switch (viewId) {
			case 1:
				return (
					<ContactInfo
						clientInfo={clientInfo}
						handleChange={handleChange}
						changeView={changeView}
						mobile={mobile}
					/>
				);
			case 2:
				return (
					<TripDetails
						clientInfo={clientInfo}
						handleChange={handleChange}
						changeView={changeView}
						handleTripType={handleTripType}
						mobile={mobile}
					/>
				);
			case 3:
				return (
					<Confirmation
						clientInfo={clientInfo}
						handleChange={handleChange}
						changeView={changeView}
						handleSubmit={handleSubmit}
						mobile={mobile}
					/>
				);
			case 4:
				return (
					<Finished
						clientInfo={clientInfo}
						handleChange={handleChange}
						changeView={changeView}
						handleSubmit={handleSubmit}
						mobile={mobile}
					/>
				);
			default:
				return (
					<ContactInfo
						clientInfo={clientInfo}
						handleChange={handleChange}
						changeView={changeView}
						mobile={mobile}
					/>
				);
		}
	};

	return (
		<div>
			{/* <Backdrop open={loading} style={{ zIndex: 99999 }} /> */}
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={snackOpen}
				autoHideDuration={3500}
				onClose={handleSnackClose}
			>
				<MuiAlert elevation={6} variant="filled" severity="success">
					Your inquiry has been submitted!
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={handleSnackClose}
						style={{ marginLeft: '20px' }}
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</MuiAlert>
			</Snackbar>
			{renderViews(obStep)}
		</div>
	);
}
